import React from 'react'
import PropTypes from 'prop-types'

const TVBHeader = ({title, subtitle, background, isMedium}) => (
  <section className="section tvb-header" style={{background: 'url(' + background +') center center / cover no-repeat rgb(22, 48, 21)', height: isMedium ? '540px' : '740px'}}>
    <h1 className="title is-1 has-text-centered" style={{marginTop: 220, marginBottom: 0}}>{title}</h1>
    <h2 className="title is-2 has-text-centered">{subtitle}</h2>
  </section>
);
export default TVBHeader;

TVBHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    background: PropTypes.string.isRequired,
    isMedium: PropTypes.bool
}