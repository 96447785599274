import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from 'react-images'
import { v4 } from 'uuid'

export default class Fotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLightBoxOpen: false, currentImage: 0};
  }

  openLightBox(index) {
    // console.log(index);
    this.setState({isLightBoxOpen: true, currentImage: index ? index : 0})
  }

  closeLightBox() {
    this.setState({isLightBoxOpen: false})
  }

  nextLightBox() {
    // console.log(this.state.currentImage)
    this.setState({currentImage: this.state.currentImage + 1})
  }

  previousLightBox() {
    this.setState({currentImage: this.state.currentImage - 1})
  }

  render() {
    const {urls, size} = this.props
    // const { edges: posts } = data.allMarkdownRemark
    const srcSet = urls.map(src => {
      return {src};
    });
    // console.log(srcSet);
    return (
      <div>
        <Lightbox
            images={srcSet} isOpen={this.state.isLightBoxOpen}
            onClose={() => this.closeLightBox()}
            onClickNext={() => this.nextLightBox()}
            onClickPrev={() => this.previousLightBox()}
            currentImage={this.state.currentImage}
        />
        <div className="columns is-multiline">
          {urls.map((url, index) => {
            return (
              <div className={'column ' + size} key={v4()}>
                <div className="tvb-inside-img"
                      style={{background: 'url('+url+') center center / cover no-repeat'}}
                      onClick={() => this.openLightBox(index)}></div>
              </div>
            )
          })}
        </div>
    </div>
    ) 
  }
}

Fotos.propTypes = {
  urls: PropTypes.arrayOf(
    PropTypes.string
  ),
  size: PropTypes.string.isRequired
}

// export default Fotos
