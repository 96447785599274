import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/Layout'
import HTMLContent from '../components/Content'
import Fotos from '../components/Fotos';
// import Fotos2 from '../components/Fotos2';
import TVBHeader from '../components/TVBHeader'

import overOnsBackGround from '../../static/img/over-ons-background.jpg'
import foto1 from '../../static/img/foto-page/foto-1.jpg'
import foto2 from '../../static/img/foto-page/foto-2.jpg'
import foto3 from '../../static/img/foto-page/foto-3.jpg'
import foto4 from '../../static/img/foto-page/foto-4.jpg'
import foto5 from '../../static/img/foto-page/foto-5.jpg'
import foto6 from '../../static/img/foto-page/foto-6.jpg'
import foto7 from '../../static/img/foto-page/foto-7.jpg'
import foto8 from '../../static/img/foto-page/foto-8.jpg'
import foto9 from '../../static/img/foto-page/foto-9.jpg'
import foto10 from '../../static/img/foto-page/foto-10.jpg'
import foto11 from '../../static/img/foto-page/foto-11.jpg'
import foto12 from '../../static/img/foto-page/foto-12.jpg'
import foto13 from '../../static/img/foto-page/foto-13.jpg'
import foto14 from '../../static/img/foto-page/foto-14.jpg'
import foto15 from '../../static/img/foto-page/foto-15.jpg'
import foto16 from '../../static/img/foto-page/foto-16.jpeg'
import foto17 from '../../static/img/foto-page/foto-17.jpg'

export const FotosPageTemplate = ({ title, content, contentComponent }) => {

  return (
    <div>
      <TVBHeader title="Foto's" background={overOnsBackGround} isMedium={true}/>
      <section className="section fotos" style={{backgroundColor: '#FBF9F6', padding: '50px'}}>
          <div className="container has-text-centered">
              <Fotos urls={[foto1, foto17, foto2, foto3, foto16, foto4, foto5, foto6, foto7, foto8, foto9, foto10, foto11, foto12, foto13, foto14, foto15]} size="is-one-quarter"/>
              {/* <Fotos2 images={[foto1, foto17, foto2, foto3, foto16, foto4, foto5, foto6, foto7, foto8, foto9, foto10, foto11, foto12, foto13, foto14, foto15]} /> */}
          </div>
      </section>
    </div>
  )
}

FotosPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const FotosPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>Foto's</title>
        <meta name="description" content="Neem een kijkje in ons bedrijf" />
      </Helmet>
      <FotosPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

FotosPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FotosPage

export const fotosPageQuery = graphql`
  query FotosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
